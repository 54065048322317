import $ from "jquery";
import {
  logFactory,
  isCompExists,
  components,
  MediaQueryHelper,
} from "GlobalSite";

const compName = "comp_aem_hp-current-offers";
const Log = logFactory(compName);
const { log } = Log;
const {
  compTechUtilTooltipmanager: { TooltipManager },
} = components;

log("--->");

$(function domReady() {
  if (!isCompExists(compName, Log)) {
    return;
  }

  new TooltipManager({
    tooltipCtaSelector: ".tooltip-cta",
    tooltipSelector: ".tooltip",
    arrowSelector: ".arrow",
    $tooltipsContainer: $(".hp-current-offers"),
  });
});
